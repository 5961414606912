import { faCircleCheck, faCircleXmark, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useSelector } from 'react-redux';
import { State } from '../../../../types';

interface Props {
    workstationName: string
}
const WorkstationStatus: React.FC<Props> = (props) => {
    const ws = useSelector((store: State) => store.applicationStatus.workstations);

    return (
        <div className="box">
            <span className="subicon" style={{ color: ws.includes(props.workstationName) ? "#43A047" : "#808080" }}><FontAwesomeIcon icon={ws.includes(props.workstationName) ? faCircleCheck : faCircleXmark} size={"1x"} /></span>
            <span className="ms-2">{ws.includes(props.workstationName) ? "Online" : "Offline"} </span>
        </div>
    );
}

export default WorkstationStatus