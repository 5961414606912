import { actions } from ".";
import { Note } from "../../../types";
import api from "../api";

export const getNotes = () => {
    return async (dispatch: (arg0: { type: string; payload: {}; }) => void) => {
        dispatch(actions.inProgressAction("get-notes"));
        try {
            let res = await api.get("/api/notes");
            if (res.status === 200) {
                dispatch(actions.updateNotesAction(res.data));
            } else {
                alert(`Error ${res.status}: ${res.data}`);
            }
        } catch (e) {

        }
        dispatch(actions.notInProgressAction("get-notes"));
    }
}

export const save = (note: Note) => {
    return async (dispatch: (arg0: { type: string; payload: {}; }) => void) => {
        dispatch(actions.inProgressAction("save-note"));
        try {
            const saveResponse = await api.put("/api/notes", note);
            if (saveResponse.status !== 200) {
                alert("Save failed")
                return
            }
            let res = await api.get("/api/notes");
            if (res.status === 200) {
                dispatch(actions.updateNotesAction(res.data));
            }
        } catch (e) {
        }
        dispatch(actions.notInProgressAction("save-note"));
    }
}

export const remove = (note: Note) => {
    return async (dispatch: (arg0: { type: string; payload: {}; }) => void) => {
        dispatch(actions.inProgressAction("delete-note"));
        try {
            const saveResponse = await api.delete(`/api/notes/${note.id}`);
            if (saveResponse.status !== 200) {
                alert("Deletion failed")
                return
            }
            let res = await api.get("/api/notes");
            if (res.status === 200) {
                dispatch(actions.updateNotesAction(res.data));
            }
        } catch (e) {
        }
        dispatch(actions.notInProgressAction("delete-note"));
    }
}
