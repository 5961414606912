import React from 'react';
import { faEye, faPause, faPlay, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux';
import { desktopIconClass } from '..';
import { putToSleep, shutdown, wakeUp } from '../../../actions/api';
import Icon from './utils/Icon';
import WorkstationStatus from './utils/WorkstationStatus';

interface Props {
}
const Neptune: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="">

                <WorkstationStatus workstationName="neptune" />

                <button onClick={() => { dispatch(wakeUp("neptune")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faPlay} inProgressId="wakeUp_neptune" />
                    </span>
                    <span className="title">
                        Wake up
                    </span>
                </button>

                <button onClick={() => { dispatch(putToSleep("neptune")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faPause} inProgressId="putToSleep_neptune" />
                    </span>
                    <span className="title">
                        Put to sleep
                    </span>
                </button>

            </div>
        </>
    );
}

export default Neptune;