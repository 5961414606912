import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserStatus, loadSettings, setSetting, setWorkstations } from '../actions';
import { AppSettings, State } from '../types';

import { io } from 'socket.io-client';
import { getAppInstallationId, setSocketId } from '../helpers/authorizationHelpers';

function DataLoader(props: any) {

    const settings: AppSettings = useSelector((store: State) => store.settings)
    const dispatch: any = useDispatch();

    useEffect(() => {
        console.log("DataLoader: Load Settings")
        if (!settings.dataLoaderHasRun) {
            console.log("Loading settings")
            dispatch(loadSettings());
            return;
        }
        dispatch(fetchUserStatus());
    });

    useEffect(() => {
        const url = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:9500';
        const socket = io(url ?? "");

        function onConnect() {
            console.log("Websocket connected")
            dispatch(setSetting({ socketStatus: "connected", socket: socket }));
        }
        function onDisconnect() {
            console.log("Websocket disconnected")
            dispatch(setSetting({ socketStatus: "disconnected", socket: undefined }));
        }
        function onWhoAreYouEvent(id: string) {
            setSocketId(id)
            socket.emit("appInstallationId", getAppInstallationId())
        }
        function onAuthenticatedEvent() {
            dispatch(setSetting({ socketStatus: "authenticated" }));
        }
        function onAuthErrorEvent() {
            console.log("Websocket did not authenticate")
            dispatch(setSetting({ socketStatus: "connected" }));
        }
        function onWorkstationUpdate(workstations: string[]) {
            dispatch(setWorkstations(workstations));
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('whoareyou', onWhoAreYouEvent);
        socket.on('authenticated', onAuthenticatedEvent);
        socket.on('authError', onAuthErrorEvent);
        socket.on('ws-update', onWorkstationUpdate);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('whoareyou', onWhoAreYouEvent);
            socket.off('authenticated', onAuthenticatedEvent);
            socket.off('authError', onAuthErrorEvent);
            socket.off('ws-update', onWorkstationUpdate);
        };
    }, [dispatch]);

    return <></>
}

export default DataLoader
